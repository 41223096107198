<template>
    <div class="sell-point">
        <b-card>
            <!-- {{ sellPointDto }} -->
            <template #header>
                <!-- {{ id }} -->
                <div class="d-flex justify-content-start align-items-center">
                    <unicon
                        name="users-alt"
                        class="mr-1"
                        style="fill: #6e6b7b"
                    ></unicon>
                    <h4 class="m-0">معلومات نقطة البيع</h4>
                </div>
                <div class="d-flex justify-content-start align-items-center">
                    <span class="mr-1">حالة الحساب</span>
                    <StatusBadge
                        :options="sellPointStatusList"
                        :value="sellPointDto.isBlocked"
                    />
                </div>
            </template>
            <validationObserver ref="sellPointForm">
                <b-row>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="الاسم والكنبة"
                            label="الاسم والكنية"
                            v-model="sellPointDto.name"
                            :rules="[
                                { type: 'required', message: 'اسم مطلوب' },
                            ]"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="البريد الالكتروني"
                            label="البريد االالكتروني"
                            v-model="sellPointDto.email"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="كلمة السر الجديدة"
                            label="كلمة السر الجديدة"
                            v-model="sellPointDto.newPassword"
                            :rules="[
                                {
                                    type: 'min:8',
                                    message:
                                        'كلمة المرور يجب ان تكون اطول من 8 محارف',
                                },
                            ]"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="رقم الهاتف"
                            label="رقم الهاتف"
                            v-model="sellPointDto.phoneNumber"
                            placeholder="********"
                            :rules="[
                                { type: 'required', message: 'رقم مطلوب' },
                                {
                                    type: 'mobile',
                                    message: 'رقم الهاتف غير صالح',
                                },
                            ]"
                        ></ek-input-text>
                        </b-col>
                        <b-col lg="4" md="6">

                        <ek-input-text
                            type="number"
                            name="رقم الواتساب"
                            label="رقم الواتساب"
                            placeholder="ادخل  رقم الواتساب"
                            v-model="sellPointDto.whatsappPhoneNumber"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-date-picker
                            v-model="sellPointDto.dateCreated"
                            name="عيد الميلاد"
                            label="تاريخ الميلاد"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'تاريخ الميلاد مطلوب',
                                },
                            ]"
                        >
                        </ek-date-picker>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="نسبة نقطة البيع"
                            label="نسبة نقطة البيع"
                            v-model="sellPointDto.rate"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'نسبة نقطة البيع مطلوبة',
                                },
                            ]"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="العنوان"
                            label="العنوان"
                            placeholder="ادخل العنوان"
                            v-model="sellPointDto.address"
                            :rules="[
                                { type: 'required', message: 'العنوان مطلوب' },
                            ]"
                        ></ek-input-text>
                    </b-col>

                    <b-col lg="4" md="6">
                        <ek-input-text
                            readonly
                            name="عدد الاكواد "
                            label="عدد الاكواد"
                            v-model="sellPointDto.codesCount"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <ek-input-text
                            name="حد الاعظمي للبيع"
                            label="حد الاعظمي للبيع"
                            v-model="sellPointDto.moneyLimit"
                            :rules="[
                                {
                                    type: 'required',
                                    message: ' حد الاعظمي للبيع',
                                },
                            ]"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <b-form-group
                            label="امكانية الحسم"
                            v-slot="{ ariaDescribedby }"
                        >
                            <b-form-radio-group
                                id="radio-slots"
                                :options="options"
                                v-model="sellPointDto.canDiscount"
                                :aria-describedby="ariaDescribedby"
                                name="امكانية الحسم"
                            >
                            </b-form-radio-group>
                        </b-form-group>

                        <ek-input-text
                            v-if="sellPointDto.canDiscount"
                            type="number"
                            placeholder="ادخل  حد الحسم"
                            name="حد الحسم"
                            label="حد الحسم"
                            v-model="sellPointDto.discountLimit"
                            :rules="[
                                { type: 'required', message: 'حد الحسم مطلوب' },
                            ]"
                        ></ek-input-text>
                    </b-col>
                    <b-col lg="4" md="6">
                        <b-form-group label="حالة نقطة البيع">
                            <b-form-radio-group
                                id="radio-isHidden"
                                :options="options1"
                                v-model="sellPointDto.isHidden"
                                name="حالة نقطة البيع"
                            >
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </validationObserver>
            <template #footer>
                <b-row>
                    <b-col lg="6">
                        <b-button
                            variant="main-color"
                            class="mr-1"
                            @click="submit()"
                            >تعديل</b-button
                        >
                        <b-button
                            variant="outline-primary"
                            @click="$router.go(-1)"
                            >تراجع</b-button
                        >
                    </b-col>
                    <b-col
                        lg="6"
                        class="d-flex justify-content-end align-items-center"
                    >
                        <b-button
                            @click="setIsBlock"
                            variant="outline-danger"
                            class="mr-1"
                            >{{
                                sellPointDto.isBlocked ? "الغاء الحظر" : "حظر"
                            }}</b-button
                        >
                        <b-button
                            @click="deletePoint(id)"
                            variant="outline-primary"
                            >حذف</b-button
                        >
                    </b-col>
                </b-row>
            </template>
        </b-card>
        <ek-table
            :title="`الرموز المشتركة`"
            :columns="columnsCode"
            :items="sellPointDto.codes"
            @details="goToDetails"
        >
            <template slot="items.endDate" slot-scope="{ value }">
                <span>{{ new Date(value).toLocaleDateString() }}</span>
            </template>
            <template slot="items.startDate" slot-scope="{ value }">
                <span>{{ new Date(value).toLocaleDateString() }}</span>
            </template>
            <template slot="items.subjetsNames" slot-scope="{ props }">
                <b-button
                    :id="`popover-target-${props.row.originalIndex}`"
                    variant="transperant"
                >
                    مرر لرؤية المواد
                    <!-- {{ props.row }} -->
                </b-button>
                <b-popover
                    :target="`popover-target-${props.row.originalIndex}`"
                    triggers="hover"
                    placement="right"
                >
                    <template #title>اسماء المواد</template>
                    <span>
                        {{
                            props.row.subjetsNames.length > 0
                                ? props.row.subjetsNames.find((ele) => ele)
                                : "لايوجد"
                        }}
                    </span>
                </b-popover>
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge.vue";
export default {
    props: ["id"],
    components: {
        StatusBadge,
    },
    data() {
        return {
            options: [
                { text: "مفعل", value: true },
                { text: "غير مفعل ", value: false },
            ],
            options1: [
                { text: "مخفي", value: true },
                { text: "ظاهر", value: false },
            ],
        };
    },
    computed: {
        ...mapState({
            sellPointsList: (state) => state.sellPoints.sellPointsList,
            sellPointDto: (state) => state.sellPoints.dtoUpdateSellPoint,
            columnsCode: (state) => state.sellPoints.columnsCode,
            sellPointStatusList: (state) => state.sellPoints.sellPointStatus,
            rateDto: (state) => state.settings.rateDto,
        }),
    },

    methods: {
        submit() {
            this.$refs["sellPointForm"].validate().then((suc) => {
                if (suc) {
                    this.updateSellPoint(this.sellPointDto);
                    //   if (this.sellPointDto.discountLimit) {
                    //     this.sellPointDto.canDiscount = true;
                    //   }
                }
            });
        },
        deletePoint(id) {
            this.deleteSellPoint(id);
        },
        setIsBlock() {
            if (!this.sellPointDto.isBlocked) {
                this.blockSellPoint(this.id);
            } else this.unblockSellPoint(this.id);
        },
        goToDetails() {
            this.$router.push("/admin/activationCode");
        },
        ...mapActions([
            "getByIdSellPoint",
            "deleteSellPoint",
            "updateSellPoint",
            "blockSellPoint",
            "unblockSellPoint",
        ]),
    },

    created() {
        this.getByIdSellPoint(this.id);
    },
};
</script>

<style lang="scss">
.sell-point {
    .card-header {
        display: flex;
        justify-content: space-between !important;
        align-items: center;
    }
    .card-footer {
        border: none !important;
    }
}
</style>
